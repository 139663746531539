<template>
  <div class="bg-gray-50 min-h-screen relative">
    <div class="p-4 pt-8 container max-w-screen-lg mx-auto">
      <period-list></period-list>
      <schedule-list class="mb-10"></schedule-list>
      <member-list></member-list>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations("period", { RESET_PERIOD: "RESET" }),
    ...mapMutations("schedule", { RESET_SCHEDULE: "RESET" }),
    ...mapMutations("member", { RESET_MEMBER: "RESET" }),
  },
  beforeDestroy() {
    this.RESET_PERIOD();
    this.RESET_SCHEDULE();
    this.RESET_MEMBER();
  },
  name: "PeriodManagement",
  components: {
    PeriodList: () => import("@/components/Registration/Period/PeriodList.vue"),
    ScheduleList: () =>
      import("@/components/Registration/Schedule/ScheduleList.vue"),
    MemberList: () => import("@/components/Registration/Member/MemberList.vue"),
  },
};
</script>